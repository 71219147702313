import { P } from "@src/components/atoms";
import { useAuthContext, withAuthContext } from "@src/context/Auth";
import { t_usr_info } from "@src/types/tables";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "next-i18next";
import styled from "styled-components";

export type LoginModalProps = {
	setProfileModal: (value: boolean) => void;
	setLoginModal: (value: boolean) => void;
};

function LoginModal(props: LoginModalProps)
{
	const { t } = useTranslation("common");

    const [ usrId, setUsrId ] = useState("");
    const [ usrPw, setUsrPw ] = useState("");

    const {
		state: { isAuthenticating },
		action: {
			signIn,
		},
	} = useAuthContext();

    async function handleSubmitLogin(event: React.FormEvent<HTMLFormElement>)
	{
		event.preventDefault();

		if (!usrId || !usrPw)
			alert("아이디와 비밀번호를 입력해주세요.");
		else
		{
			const loginBody = { usrId, usrPw };

			try
			{
				signIn(loginBody as unknown as t_usr_info);

				props.setLoginModal(false);
				props.setProfileModal(true);
			}
			catch (e)
			{
				console.log(e);
			}
		}
	}

    return (
        <Wrapper style={{ right: "-30px" }}>
            <LoginForm onSubmit={ handleSubmitLogin }>
                <Text style={{ marginBottom: "24px", lineHeight: 1, cursor: "default" }}>{ "로그인" }</Text>
                <Input type="text" placeholder={ "아이디" } onChange={(e) => { setUsrId(e.target.value) }} />
                <Input type="password" placeholder={ "비밀번호" } onChange={(e) => { setUsrPw(e.target.value) }} />
                <CheckboxWrapper>
                    <CustomCheckbox type="checkbox" id="saveid" />
                    {/* <CustomLabel htmlFor="saveid">
                        <SmallText2> 아이디 저장 </SmallText2>
                    </CustomLabel> */}
                </CheckboxWrapper>
                <ConfirmButton onClick={ handleSubmitLogin }>
                    <Text>{ "로그인" }</Text>
                </ConfirmButton>
            </LoginForm>
        </Wrapper>
    )
}

export default withAuthContext(LoginModal);

const Wrapper = styled.div`
	width: 360px;
	display: flex;
	flex-direction: column;
	padding: 20px;
	border-radius: 8px;
	background: #FFF; 
	position: absolute;
	top: 78px;
	z-index: 2;

	* {
		margin-bottom: 12px;
	};
`;

const LoginForm = styled.form`
	width: 100%;
	margin: 0;
	font-size: 0;
`;

const Text = styled(P)`
	width: fit-content;
	color: #271565;
	font-size: 20px;
	font-weight: 700;
	line-height: 45px; 
	flex: 1;
	display: flex;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const Input = styled.input`
	width: 320px;
	height: 48px; 
	padding: 0 16px;
	border-radius: 8px;
	border: 1px solid #999;
	background: #FFF; 
	color: #000;
	text-align: left;
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: normal; 
`;

const ConfirmButton = styled(Button)`
	width: 147px;
	height: 40px; 
	margin: 0;
	border-radius: 8px;
	border: 1px solid #999;
	background: #FFF; 
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

	p {
		font-size: 16px;
        line-height: 1;
	};
`;

const CheckboxWrapper = styled.div`
	width: fit-content;
	height: fit-content;
	display: flex;
	margin-left: auto;
`;

const CustomLabel = styled.label`
	position: relative;
	display: flex;
	align-items: center;
	user-select: none;
	margin-bottom: 0;

	&:before {
		content: "";
		width: 20px;
		height: 20px;
		border: 2px solid #BCBEBF;
		border-radius: 50%;
		margin-right: 5px;
  	};

	&:after {
		opacity: 0;
		content: "";
		position: absolute;
		width: 20px;
		height: 20px;
		border: 2px solid #BCBEBF;
		border-radius: 50%;
		background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
		background-size: 100% 100%;
		background-position: 50%;
		background-repeat: no-repeat;
		background-color: #168dcd;
  	};
`;

const CustomCheckbox = styled.input`
    position: absolute;
  	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	overflow: hidden;
	white-space: nowrap;
	border-radius: 8px;
	background: #110831; 

	&:checked + ${CustomLabel} {
		&:after {
			opacity: 1;
		}
   	}
`;