import * as AWS from 'aws-sdk'

export const s3 = new AWS.S3({
    accessKeyId: process.env.BUCKIT_ACCESS_KEY!,
    secretAccessKey: process.env.BUCKET_SECRET_KEY!
});

export const bucketParams = (file: any, fileName: string, type: string) => {
    const params = {
        Bucket: process.env.BUCKET_NM!,
        Key: fileName,
        Body: file,
        ContentType: type
    }

    return params;
};

export const downloadParams = (fileName: string) => {
    const params = {
        Bucket: process.env.BUCKET_NM!,
        Key: fileName,
    };

    return params;
};