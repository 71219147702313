import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

const UnAuthNavigationContainer = dynamic(() => import('./unauth-navigation/UnAuthNavigationContainer'));
import NavigationItems from './items/NavigationItems';
// import UnAuthNavigationContainer from './unauth-navigation/UnAuthNavigationContainer';

import { getCookie } from 'src/lib/util/cookie';
import cssMixin from 'src/styles';
import { P } from '@src/components/atoms';
import { ThemeType } from '@src/types/theme';
import { useTranslation } from 'next-i18next';
import LangSelect from './items/LangSelect';
import { useRouter } from 'next/router';

export type NavigationProps = {
	transparent?: boolean;
};

function Navigation(props: NavigationProps)
{
	const [ isLoggedIn, setIsLoggedIn ] = useState(false);

	const router = useRouter();

	return (
		<Wrapper>
			{/* <LangWrapper>
				<LangSelect />
			</LangWrapper> */}
			<NavigationItems color={ props.transparent ? "white" : "#271565" } />
			{/* { !isLoggedIn && <UnAuthNavigationContainer /> } */}
		</Wrapper>
	);
}
export default React.memo(Navigation);

const Wrapper = styled.div`
	${ cssMixin.flexRow };
	width: fit-content;
	justify-content: flex-end;
	flex: 1;
	display: flex;
`;

const LangWrapper = styled.div`
	${({ theme }: { theme: ThemeType }) => theme.largeTablet!`
		display: none;
	`}
`;