import { P } from "@src/components/atoms";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { Button } from "react-bootstrap";
import styled from "styled-components";

export default function SignupModal()
{
	const { t } = useTranslation("common");

    return (
        <Wrapper style={{ right: "342px" }}>
			<Text style={{ marginBottom: "24px", lineHeight: 1, cursor: "default" }}>{ "회원가입" }</Text>
			<Link href="/join">
				<BtnYulbgm>{ `YULBGM ${"회원가입"}` }</BtnYulbgm>
			</Link>
			<TextWrapper style={{ margin: 0 }}>
				<SmallText>
					{ "회원가입을 하시면 " }
					<SmallText2>{ "서비스 이용약관  " }</SmallText2>
					{ "과 " }
					<SmallText2>{ "개인정보 처리방침 " }</SmallText2>
					{ "에 동의하는 것으로 간주합니다." }
				</SmallText>
			</TextWrapper>
		</Wrapper>
    );
}

const Wrapper = styled.div`
	width: 360px;
	display: flex;
	flex-direction: column;
	padding: 20px;
	border-radius: 8px;
	background: #FFF; 
	position: absolute;
	top: 78px;
	z-index: 2;

	* {
		margin-bottom: 12px;
	};
`;

const Text = styled(P)`
	width: fit-content;
	color: #271565;
	font-size: 20px;
	font-weight: 700;
	line-height: 45px; 
	flex: 1;
	display: flex;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const SmallText = styled(Text)`
	height: fit-content;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 1.5;
	flex: 0;
	cursor: initial;
`;

const SmallText2 = styled.span`
	height: fit-content;
	color: #271565;
	font-size: 14px;
	font-weight: 700;
	text-decoration-line: underline; 
	cursor: pointer;
`;

const BtnYulbgm = styled(Button)`
	width: 320px;
	height: 48px;
	color: #FFFBFB;
	border-radius: 8px;
	border: none;
	background: #110831; 
	color: #FFF;
	text-align: center;
	font-family: Pretendard;
	font-size: 16px;
	font-weight: 700;
	line-height: normal; 
	cursor: pointer
`;

const TextWrapper = styled.div`
	width: 100%;
	display: flex;

	p {
		width: fit-content;
		margin: 0 5px 0 0;
		justify-content: unset;
		text-align: no;
		display: initial;
		flex: unset;
	};
`;