import { Drawer } from "@mui/material";
import { P } from "@src/components/atoms";
import X from "@src/components/icons/X";
import { handleLogOut } from "@src/lib/public-lib";
import { getCookie } from "@src/lib/util/cookie";
import { RootState, useSelector } from "@src/store";
import { darkTheme } from "@src/theme/default";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import styled from "styled-components";
import LangSelect from "../LangSelect";

export type MobileMenuDrawerProps = {
    openMenu: boolean;
    setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
};

export default function MobileMenuDrawer(props: MobileMenuDrawerProps)
{
    const { t } = useTranslation("common");
    const user = useSelector((state: RootState) => state.user);
    const router = useRouter();

    const [ login, setLogin ] = useState<boolean>(!!getCookie("accessToken"));
    
    function handleCartDeny()
    {
        if (!login)
            alert("로그인이 필요합니다.");
    }

    return (
        <Drawer
            PaperProps={
                { sx: { width: "100%", height: "100%", color: darkTheme.textHighlight, backgroundColor: "#1B0E47" }}
            }
            anchor="right"
            open={ props.openMenu }
            onClose={ () => props.setOpenMenu(false) }
        >
            <TopWrapper>
                <StyledP style={{ color: "#F3B25A", cursor: "unset" }}>
                    { login ?
                        `$user.nickName}님, 안녕하세요!`
                        :
                        `${"로그인"}/${"회원가입"}`
                    }
                </StyledP>
                <X
                    style={{ cursor: "pointer" }}
                    width={ 14 }
                    height={ 14 }
                    fill="#fff"
                    onClick={ () => props.setOpenMenu(false) }
                />
            </TopWrapper>
            <MenuWrap>
                { login ?
                    <>
                        <Link href={ `/mypage/${user.id}` }>
                            <StyledP>{ "개인정보 수정" }</StyledP>
                        </Link>
                        <Link href={ `/history/${user.id}` }>
                            <StyledP>{ "구매내역" }</StyledP>
                        </Link>
                        <StyledP onClick={ () => handleLogOut("로그아웃 하시겠습니까?") }>{ "로그아웃" }</StyledP>
                        <Line />
                    </>
                    :
                    <>
                        <Link href={ `/join` }>
                            <StyledP>{ "회원가입" }</StyledP>
                        </Link>
                        <Link href={ `/login` }>
                            <StyledP>{ "로그인" }</StyledP>
                        </Link>
                        <Line />
                    </>
                }
                <Link href="/page/1">
                    <StyledP>{ "음악" }</StyledP>
                </Link>
                <Link href={ login ? "/cart" : "/login" } onClick={ handleCartDeny }>
                    <StyledP>{ "장바구니" }</StyledP>
                </Link>
                <Line />
                <Link href="/notify">
                    <StyledP>{ "공지사항" }</StyledP>
                </Link>
                <Link href="/introduce">
                    <StyledP>{ "이용안내" }</StyledP>
                </Link>
                <Link href="/help">
                    <StyledP>{ "FAQ" }</StyledP>
                </Link>
                <Link href="/inquiry">
                    <StyledP>{ "문의하기" }</StyledP>
                </Link>
                <div style={{ height: 20 }} />
                {/* <LangSelect /> */}
            </MenuWrap>
        </Drawer>
    );
}

const TopWrapper = styled.div`
    width: 100%;
    height: 56px;
    background-color: #251364;
    color: #F3B25A;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledP = styled(P)`
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 50px; 
    color: #fff;
    cursor: pointer;
`;

const MenuWrap = styled.div`
    padding: 12px 20px;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #373737;
    margin: 12px 0;
`;