import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import cssMixin from '@src/styles';
import { getCookie } from '@src/lib/util/cookie';
import { RootState } from '@src/store';
import { useSelector } from 'react-redux';
import { CgProfile } from "react-icons/cg";
import MenuIcon from '@src/components/icons/header/MenuIcon';
import { P } from '@src/components/atoms';
import { handleLogIn, handleLogOut } from '@src/lib/public-lib';
import Cart from '@src/components/icons/cart/Cart';
import ProfileModal from './modals/ProfileModal';
import LoginModal from './modals/LoginModal';
import SignupModal from './modals/SignupModal';
import MobileMenuDrawer from './drawers/MobileMenuDrawer';
import { useTranslation } from 'next-i18next';

export type NavigationItemsProps = {
	color?: string;
};

export default function NavigationItems(props: NavigationItemsProps)
{
	const { t } = useTranslation("common");
	const media = useSelector((state: RootState) => state.media);
	const router = useRouter();

	const [ usrId, setUsrId ] = useState("");
    const [ usrPw, setUsrPw ] = useState("");
	const [ clickProfile, setClickProfile ] = useState(false);
	const [ profileModal, setProfileModal ] = useState(false);
	const [ loginModal, setLoginModal ] = useState(false);
	const [ signUpModal, setSignUpModal ] = useState(false);
	const [ openMenu, setOpenMenu ] = useState(false);

	function handleCartClick()
	{
		if (!!getCookie("accessToken"))
			router.push("/cart" );
		else
			handleLogIn("로그인이 필요합니다.");
	}

	function handleProfileClick()
	{
		setClickProfile(!clickProfile);
	};

	function handleCloseModal()
	{
		setClickProfile(false);
		setProfileModal(false);
		setLoginModal(false);
		setSignUpModal(false);
	}

	useEffect(() => {
		if (clickProfile)
		{
			if (!!getCookie("accessToken"))
				setProfileModal(true);
			else
			{
				setLoginModal(true);
				setSignUpModal(true);
			}
		}
	}, [clickProfile]);

	return (
		<>
			{ clickProfile &&
				<div style={{ width: "100vw", height: "100vh", position: "fixed", top: 0, left: 0, background: "#00000055", zIndex: 2 }} onClick={ handleCloseModal } />
			}
			<Wrapper>
				<Item style={{ margin: "0 20px" }}>
					<Cart width={ 30 } height={ 30 } fill={ props.color } onClick={ handleCartClick } />
				</Item>
				<Item>
					{ media.isDesktop || media.deviceLevel === "xlargeTablet" || media.deviceLevel === "largeTablet" ?
						<CgProfile color={ props.color } size={ 30 } onClick={ handleProfileClick } />
						:
						<MenuIcon fill={ props.color } width={ 30 } height={ 30 } onClick={ () => setOpenMenu(!openMenu) } />
					}
				</Item>
				{ profileModal &&
					<ProfileModal setProfileModal={ setProfileModal } setLoginModal={ setLoginModal } />
				}
				{ loginModal && 
					<>
						<LoginModal setProfileModal={ setProfileModal } setLoginModal={ setLoginModal } />
						<SignupModal />
					</>
				}
				<MobileMenuDrawer openMenu={ openMenu } setOpenMenu={ setOpenMenu } />
			</Wrapper>
		</>
	);
}

const Wrapper = styled.div`
	${ cssMixin.flexRow }
	position: relative;
`;

const Item = styled.div`
	width: 30px;
	height: 30px;
	/* padding: 0 0.15rem; */
	/* position: relative; */

	* {
		cursor: pointer;
	};
`;

const Text = styled(P)`
	width: fit-content;
	color: #271565;
	font-size: 20px;
	font-weight: 700;
	line-height: 45px; 
	flex: 1;
	display: flex;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;