import { IconProps } from "@src/types";

export default function MenuIcon(props: IconProps)
{
    const width = props.width ? props.width : 25;
    const height = props.height ? props.height : 25;
    const fill = props.fill ? props.fill : "none";

    return (
        <svg width={ width } height={ height } viewBox={ `0 0 24 24` } fill={ fill } xmlns="http://www.w3.org/2000/svg" style={ props.style } onClick={ props.onClick }>
            <path d="M3 6H21V8H3V6ZM3 11H21V13H3V11ZM3 16H21V18H3V16Z" /> 
        </svg> 
    );
}