import styled from 'styled-components';

import { BreakPointType, BreakPoint } from 'src/styles/media';
import { GUTTER, MOBILE_GUTTER } from 'src/styles';
import { ThemeType } from 'src/types/theme';

export type ColProps = BreakPointType;

const COLUMN = 21;
const MOBILE_COLUMN = 4;

const calcWidthPercent = (span: number, column: number) => {
	if (!span) return;

	const width = (span / column) * 100;

	return width;
};

const Col = styled.div<ColProps>`
	/* width: 100%; */
	height: fit-content;
	/* margin-right: ${GUTTER}px; */

	${({ theme }: { theme: ThemeType }) => theme.tablet!`
		margin-right: ${MOBILE_GUTTER}px;
	`}

	${({ smallMobile }) =>
		smallMobile &&
		`width: ${calcWidthPercent(smallMobile, MOBILE_COLUMN)}%;			
	`}
	@media (min-width: ${BreakPoint.mediumMobile + 1}px) {
		width: ${({ mediumMobile }) => mediumMobile && `${calcWidthPercent(mediumMobile, MOBILE_COLUMN)}%`};
	}
	@media (min-width: ${BreakPoint.mobile + 1}px) {
		width: ${({ mobile }) => mobile && ` ${calcWidthPercent(mobile, MOBILE_COLUMN)}%`};	
	}
	@media (min-width: ${BreakPoint.smallTablet + 1}px) {
		width: ${({ smallTablet }) => smallTablet && `${calcWidthPercent(smallTablet, COLUMN)}%`};
	}
	@media (min-width: ${BreakPoint.tablet + 1}px) {
		width: ${({ tablet }) => tablet && ` ${calcWidthPercent(tablet, COLUMN)}%;`};
	}
	@media (min-width: ${BreakPoint.largeTablet + 1}px) {
		width: ${({ largeTablet }) => largeTablet && `${calcWidthPercent(largeTablet, COLUMN)}%`};
	}
	@media (min-width: ${BreakPoint.xlargeTablet + 1}px) {
		width: ${({ xlargeTablet }) => xlargeTablet && `${calcWidthPercent(xlargeTablet, COLUMN)}%`};
	}
	@media (min-width: ${BreakPoint.labtop + 1}px) {
		/* width: ${({ labtop }) => labtop && `${calcWidthPercent(labtop, COLUMN)}%`}; */
		margin-right: ${({ labtop }) => labtop && `${calcWidthPercent(labtop, COLUMN * 4)}%`};
	}
	@media (min-width: ${BreakPoint.labtopL + 1}px) {
		/* width: ${({ labtopL }) => labtopL && `${calcWidthPercent(labtopL, COLUMN)}%`}; */
		margin-right: ${({ labtopL }) => labtopL && `${calcWidthPercent(labtopL, COLUMN)}%`};
	}
`;

export default Col;
