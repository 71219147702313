import styled from 'styled-components';

type SpaceProps = {
	direction?: 'COL' | 'ROW';
	level?: number;
	size?: number;
};

const Space = styled.div<SpaceProps>(({ direction = 'COL', level = 0, size }: SpaceProps) => {
	const thickness = size ? size / 100 : 0.04 + level * 0.08;
	
	return {
		backgroundColor: 'transparent',
		width: direction === 'COL' ? '0.1px' : `${thickness}rem`,
		height: direction === 'COL' ? `${thickness}rem` : '0.1px',
	};
});

export default Space;
