import { ResImg } from '@src/components/atoms';
import { ResImgProps } from '@src/components/atoms/ResImg';
import { ThemeType } from '@src/types/theme';
import React from 'react';
import styled from 'styled-components';

export type ThumbnailProps = { 
	setLabel?: boolean;
	size? : string;
} & ResImgProps;

export default function Thumbnail(props: ThumbnailProps)
{
	return (
		<Wrapper size={props.size}>
			<ResImg {...props} />
		</Wrapper>
	);
}

const Wrapper = styled.div<{ size?: string }>`
	width: ${ props => props.size ? props.size : "100%" };
	height: ${ props => props.size ? props.size : "100%" };
	min-width: 70px;
	min-height: 70px;
	max-width: 100px;
	max-height: 100px;
	border-radius: 10%;
	overflow: hidden;
	font-size: 0;
	position: relative;

	${({ theme, size }: { theme: ThemeType, size?: string }) => theme.breakpoint.labtop!`
		width: ${size};
		height: ${size};
		min-width: 0;
		min-height: 0;
	`};
`;
