import React from 'react';
import styled from 'styled-components';

export type CheckBoxProps = {
	value: boolean;
	setValue: React.Dispatch<React.SetStateAction<boolean>>;
	style?: React.CSSProperties;
};

export default function CheckBox({ value, setValue, style }: CheckBoxProps)
{
	const handleClick = () => {
		setValue(!value);
	};

	return (
		<CheckBnt type="checkbox" style={ style } checked={ value } onChange={ handleClick } />
	);
}

const CheckBnt = styled.input`
	min-width: 0.18rem;
	aspect-ratio: 1;
    margin: 0;
	appearance: none;
	border: 2px solid gainsboro;
	border-radius: 15%;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, .2);
    vertical-align: middle;

	&:checked {
		border-color: transparent;
		background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
		background-size: 150% 150%;
		background-position: 50%;
		background-repeat: no-repeat;
		background-color: #F3B25A;
	}
`;