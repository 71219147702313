import React from 'react';
import styled from 'styled-components';

import cssMixin from 'src/styles';
import FooterInfo from './info/FooterInfo';
import { Color, P } from 'src/components/atoms';
import buttonStyle from 'src/styles/button';

export type FooterProps = {
	backgroundColor?: string;
};

export default function Footer(props: FooterProps)
{
	return (
		<Background style={ props }>
			<MobileHeader />
			<Wrapper>
				<FooterInfo />
			</Wrapper>
		</Background>
	);
}

const Background = styled.div`
	${ cssMixin.flexCol };
	width: 100%;
	border-top: 1px solid ${ Color.GREY };
	background-color: #09031D;
`;

const Wrapper = styled.div`
	${ cssMixin.container };
	${ cssMixin.flexRow };
	width: 100%;
	align-items: flex-start;
	padding: 40px 35px;

	${({ theme }) => theme.labtop`
		justify-content:space-between;
	`}
`;

const IconWrapper = styled.button`
	display: none;
	${({ theme }) => theme.breakpoint.mobile`
		${buttonStyle.Default};
		height:	0.3rem;	
	`}
`;

const MobileHeader = styled.div`
	display: none;

	${({ theme }) => theme.breakpoint.mobile`
		${cssMixin.flexRow};
		width: 100%;
		height:	0.3rem;
		padding-left: 0.20rem;
		padding-right: 0.20rem;
		justify-content: space-between;
	`}
`;
