import axios, { AxiosError, AxiosResponse } from 'axios';

import {
	signInConfig,
	signUpConfig,
	verifyPhoneNumberConfig,
	verifyNumberConfig,
	refreshConfig,
	updateProfileConfig,
	verifyIdConfig,
	verifyNickConfig,
	verifyEmailconfig,
	signInEtcConfig,
	modifyConfig,
} from './config';

import { setCookie, removeCookie, getCookie } from 'src/lib/util/cookie';
import { ProfileParamsType, VerifyEmailParam, VerifyIdParam, VerifyNickParam } from 'src/types/auth';
import { CustomError, HTTPError } from 'src/models/error/http';
import { t_usr_info } from '@src/types/tables';
import Router from 'next/router';

const signOut = async (alertText?: string) => {
	removeCookie('accessToken');
	removeCookie('refreshToken');
	removeCookie('id');
	removeCookie('email');
	removeCookie('userNick');
	removeCookie('isLoggedIn');

	if (process.browser)
		localStorage.removeItem('userData');

	if (alertText) {
		alert(alertText);
	}

	Router.push("/");
};

const signUp = async (data: t_usr_info) => {
	const res = await axios(signUpConfig(data));
	return res;
}

const signIn = async (data: t_usr_info) => {
	const res = await axios(signInConfig(data));
	return res; // 로그인 성공의 경우 (200)
};

const signInEtc = async (userId: { userId: string }) => {
	const res = await axios(signInEtcConfig(userId));
	return res; // 로그인 성공의 경우 (200)
};

const verifyId = async (data: VerifyIdParam) =>
	axios(verifyIdConfig(data))
		.then(res => res.data)
		.catch((e: AxiosError) => {
			throw e;
		});

const verifyNick = async (data: VerifyNickParam) =>
	axios(verifyNickConfig(data))
		.then(res => res.data)
		.catch((e: AxiosError) => {
			throw e;
		});

const verifyEmail = async (data: VerifyEmailParam) =>
	axios(verifyEmailconfig(data))
		.then(res => res.data)
		.catch((e: AxiosError) => {
			throw e;
		});


const verifyPhoneNumber = async (data: { phoneNumber: string }) =>
	axios(verifyPhoneNumberConfig(data))
		.then(res => res.data)
		.catch((e: AxiosError<CustomError>) => {
			throw new HTTPError(e.response!.data);
		});

const verifyNumber = async (data: { phoneNumber: string; verifyNumber: string }) =>
	axios(verifyNumberConfig(data))
		.then(res => res.data)
		.catch((e: AxiosError<CustomError>) => {
			throw new HTTPError(e.response!.data);
		});

		
const refresh = async () => {
	const accessToken = getCookie('accessToken');
	const refreshToken = getCookie('refreshToken');

	const signIn = ({ accessToken, refreshToken }: { accessToken: any, refreshToken: any }) => {
		setCookie('accessToken', accessToken);
		setCookie('refreshToken', refreshToken);
		setCookie('isLoggedIn', true);
	};

	await axios(refreshConfig({ accessToken, refreshToken }))
	.then((res: AxiosResponse<{ accessToken: any; refreshToken: any; nickname: any }>) => {
		const { accessToken, refreshToken } = res.data;
		signIn({ accessToken, refreshToken });
	})
	.catch((e: AxiosError<CustomError>) => {
		console.log(e);
	});
};
 
const updateProfile = async (data: ProfileParamsType): Promise<void> =>
	axios(updateProfileConfig(data))
		.then(res => res.data)
		.catch((e: AxiosError<CustomError>) => {
			throw new HTTPError(e.response!.data);
});

const modifyData = async (data: t_usr_info): Promise<void> =>
	axios(modifyConfig(data))
		.then(res => res.data)
		.catch((e: AxiosError<CustomError>) => {
			throw new HTTPError(e.response!.data);
});

const AuthService = {
	signIn,
	signInEtc,
	signUp,
	signOut,
	verifyId,
	verifyNick,
	verifyEmail,
	verifyPhoneNumber,
	verifyNumber,
	refresh,
	updateProfile,
	modifyData,
};

export default AuthService;
