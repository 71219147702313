import qs from 'qs';

import { baseConfig, restConfig } from '../api';
import {
	ReIssueParam,
	VerifyNumberParam,
	VerifyPhoneNumberParam,
	ProfileParamsType,
	VerifyIdParam,
	VerifyNickParam,
	VerifyEmailParam,
} from '@src/types/auth';
import { t_usr_info } from '@src/types/tables';

export const signInConfig = (data: t_usr_info) =>
	restConfig().post('/auth/login', qs.stringify(data));

export const signInEtcConfig = (userId: { userId: string }) =>
	baseConfig().post('/api/auth/getUserEtc', userId);

export const signUpConfig = (data: t_usr_info) =>
	restConfig().post('/auth/signup', qs.stringify(data), {headers: {"Content-Type": "application/json"}});

export const refreshConfig = (data: ReIssueParam) =>
	restConfig().post('/auth/reissue', qs.stringify(data));

export const  verifyIdConfig = (data: VerifyIdParam) =>
	restConfig().get('/auth/hasUsrId?' + qs.stringify(data));

export const  verifyNickConfig = (data: VerifyNickParam) =>
	restConfig().get('/auth/hasUsrNicNm?' + qs.stringify(data));

export const  verifyEmailconfig = (data: VerifyEmailParam) =>
	restConfig().get('/auth/hasEmail?' + qs.stringify(data));

export const verifyPhoneNumberConfig = (data: VerifyPhoneNumberParam) =>
	restConfig().post('/auth/', qs.stringify(data));

export const verifyNumberConfig = (data: VerifyNumberParam) =>
	restConfig(true).post('/auth/verify', qs.stringify(data));

export const updateProfileConfig = (data: ProfileParamsType) =>
	restConfig(true).post('/user/setProfile/', qs.stringify(data));

export const modifyConfig = (data: t_usr_info) =>
	restConfig(true).post('/auth/userUpdate', qs.stringify(data));