import { P } from "@src/components/atoms";
import { handleLogOut } from "@src/lib/public-lib";
import { getCookie } from "@src/lib/util/cookie";
import { RootState } from "@src/store";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

export type ProfileModalProps = {
	setProfileModal: (value: boolean) => void;
	setLoginModal: (value: boolean) => void;
};


export default function ProfileModal(props: ProfileModalProps)
{
	const { t } = useTranslation("common");
	const user = useSelector((state: RootState) => state.user);
	
	const [ nickname, setNickname ] = useState("");
	const [ isLoggedIn, setIsLoggedIn ] = useState(false);

	function handleLogout()
	{
		handleLogOut("로그아웃 하시겠습니까?");

		props.setProfileModal(false);
		props.setLoginModal(true);
	}

	useEffect(() => {
		if (process.browser)
		{
			setIsLoggedIn(!!getCookie('accessToken'));
			setNickname(user.nickName);
		}
	}, []);

    return (
        <Wrapper style={{ right: "-30px" }}>
			<Text style={{ flex: 0.5, lineHeight: 2, cursor: "default" }}>{ `${nickname}님, 안녕하세요!` }</Text>
			<SmallText style={{ marginBottom: "15px" }}>{ user.email }</SmallText>
			<Link href={ isLoggedIn ? "/mypage/" + nickname : "/login" } style={{ display: "inherit", flex: 1 }}>
				<Text>{ "개인정보 수정" }</Text>
			</Link>
			<Link href={ isLoggedIn ? "/history/" + nickname : "/login" } style={{ display: "inherit", flex: 1 }}>
				<Text>{ "구매내역" }</Text>
			</Link>
			<Text onClick={ handleLogout }>
				{ "로그아웃" }
			</Text>
			<Link href="/withdrawal">
				<SmallText2>{ "회원탈퇴" }</SmallText2>
			</Link>
		</Wrapper>
    )
}

const Wrapper = styled.div`
	width: 360px;
	display: flex;
	flex-direction: column;
	padding: 20px;
	border-radius: 8px;
	background: #FFF; 
	position: absolute;
	top: 78px;
	z-index: 2;

	* {
		margin-bottom: 12px;
	};
`;

const Text = styled(P)`
	width: fit-content;
	color: #271565;
	font-size: 20px;
	font-weight: 700;
	line-height: 30px; 
	flex: 1;
	display: flex;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const SmallText = styled(Text)`
	height: fit-content;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 1.5;
	flex: 0;
	cursor: initial;
`;

const SmallText2 = styled(SmallText)`
	margin-right: 0;
	justify-content: end;
	text-decoration-line: underline; 
	flex: 1;
	cursor: pointer;
`;

const Input = styled.input`
	width: 320px;
	height: 48px; 
	padding: 0 16px;
	border-radius: 8px;
	border: 1px solid #999;
	background: #FFF; 
	color: #000;
	text-align: left;
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: normal; 
`;