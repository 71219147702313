import { t_library } from '@src/types/tables';
import { IncomingMessage } from 'http';
const qs = require('qs');

import { baseConfig } from '../api';

export const listConfig = (userId: { userId: string }) => 
	baseConfig().post('/api/cart/getCartList', qs.stringify(userId));

export const addConfig = (data: { libraryId: string, userId: string}) =>
	baseConfig().post('/api/cart/addToCart', qs.stringify(data));

export const deleteConfig = (items: { libraryId: string }) =>
	baseConfig().patch(`/api/cart/DeleteCartItems`, qs.stringify(items));

export const LibrariesConfig = (userId: { userId: string }) => 
	baseConfig().post('/api/cart/getCartLibraries', qs.stringify(userId));

	// baseConfig().delete(`/ITEMS/${id}`);