import React, { useContext, createContext, useState } from 'react';
import { useRouter } from 'next/router';
import AuthService from '@src/services/auth/AuthService';
import { setCookie } from '@src/lib/util/cookie';
import { UserType } from '@src/types/user';
import { t_usr_info } from '@src/types/tables';
import { VerifyIdParam } from '@src/types/auth';
import jwt from "jsonwebtoken";
import { useDispatch } from 'react-redux';
import { userActions } from '@src/store/user';
import CartService from '@src/services/cart/CartService';
import { useTranslation } from 'next-i18next';

const AuthContext = createContext({
	state: {
		isAuthenticating: false,
		isAgreeing: false,
		isProfileing: false,
		isAlreadyExistUser: false,
		userData: new Object() as UserType,
	},
	action: {
		signIn: (data: t_usr_info) => Promise<void>,
		signUp: (data: t_usr_info) => Promise<void>,
		requestLogin: new Object(),
		signInRoute: new Object(), 
		signUpRoute: new Object(),
	},
});

export const useAuthContext = () => useContext(AuthContext);

export const withAuthContext = (WrappedComponent: any) => (props: any) => {
	const { t } = useTranslation("common");

	const [isAuthenticating, setIsAuthenticating] = useState(false);
	const [isAgreeing, setIsAgreeing] = useState(false);
	const [isProfileing, setIsProfileing] = useState(false);	

	const [isAlreadyExistUser, setIsAlreadyExistUser] = useState(false);
	const [userData, setUserData] = useState({} as unknown as t_usr_info);
	const router = useRouter();
	const dispatch = useDispatch();

	const signInRoute = () => {
		if (router.query.redirectTo)
			router.replace(router.query.redirectTo.toString());
		else
			router.replace('/page/1');
	};
	
	const signUpRoute = () => {
		router.replace('/page/1');
		// if (router.query.redirectTo) {
		// 	router.replace('/sign-up-complete?redirectTo=' + router.query.redirectTo.toString());
		// } else {
		// 	router.replace('/sign-up-complete');
		// }
	};

	const signIn = async (data: t_usr_info) => {
		try
		{
			setIsAuthenticating(true);
			const res = await AuthService.signIn(data);
			
			if (res.status == 200 || res.status == 201)
			{
				setTimeout(() => {
					signInRoute();
					setIsAuthenticating(false);
				}, 1000);

				let accessToken: string = res.data.accessToken;
				let refreshToken: string = res.data.refreshToken;
				let token: any = jwt.decode(accessToken);

				setCookie('accessToken', accessToken);
				setCookie('refreshToken', refreshToken);		
				
				const singInEtc = (await AuthService.signInEtc(token.sub)).data;
				
				dispatch(userActions.setLoggedUser({
					isLogged: true,
					id: token.sub,
					email: token.usrEmail,
					nickName: token.usrNick,
					birthday: '',
					profileImage: '',
					loginDate: singInEtc.result[0]
				}));
			}
			else
			{
				signOut();
			}
		}
		catch (err: any)
		{	
			console.log(err)
		}
	};

	const signUp = async (data: t_usr_info) => {
		try
		{
			const res = await AuthService.signUp(data);

			if (res.status === 200) {
				// 회원가입 성공의 경우 (200)
				await AuthService.signIn(data as any);

				// setIsAgreeing(false);
				// setIsProfileing(true);
				alert("회원가입이 완료되었습니다");
				signUpRoute();
			}
		}
		catch (err: any)
		{
			if (err.response.status == '412'){
				// 이미 존재하는 사용자의 경우
				await AuthService.signIn(data as any);
				setCookie('email', data.email);

				setIsAgreeing(false);
				setIsProfileing(true);
			}else{
				console.log(err);
			}
		}
	};

	const requestLogin = async (data : t_usr_info) => {
		// data 모양 : { providerType, providerId, name, email, phoneNumber }
		setUserData(data as any);

		// // "이전에 로그인한 계정이에요" 를 위한 쿠키
		// setCookie('lastLoggedInProviderType', data.providerType);

		signIn(data);
	};

	const authStore = {
		state: {
			isAuthenticating,
			isAgreeing,
			isProfileing,
			isAlreadyExistUser,
			userData,
		},
		action: {
			signIn,
			signUp,
			requestLogin,
			signInRoute,
			signUpRoute,
		},
	};

	return (
		<AuthContext.Provider value={authStore as any}>
			<WrappedComponent { ...props } />
		</AuthContext.Provider>
	);
};



function signOut() {
	throw new Error('Function not implemented.');
}

