import { P } from "@src/components/atoms";
import { ThemeType } from "@src/types/theme";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { MouseEvent, RefObject, useEffect, useRef, useState } from "react";
import styled from "styled-components"

export type CenterButtonsProps = {
    transparent?: boolean;
}

export default function CenterButtons(props: CenterButtonsProps)
{
    const tabRefArray: RefObject<HTMLDivElement>[] = Array.from({ length: 5 }, () => useRef<HTMLDivElement>(null));
    const barArray: RefObject<HTMLParagraphElement > = useRef<HTMLParagraphElement >(null);
    const { t } = useTranslation('header')

    const [ isHover, setIsHover ] = useState(false);
    const [ barWidth, setBarWidth ] = useState(0);
    const [ barPos, setBarPos ] = useState(0);
    const [ lastTab, setLastTab ] = useState<HTMLParagraphElement >();

    function handleMouseOver()
    {
        setIsHover(true);
    }

    function handleMouseOut()
    {
        setIsHover(false);
    }

    function handleTabOver(event: MouseEvent, index: number)
    {
        let currentTab = event.target as HTMLParagraphElement;
        let leftPos: number = 0;

        for (let i = 0; i < index; i++)
        {
            let tabBoundRect: DOMRect = tabRefArray[i].current!.getBoundingClientRect();
            leftPos += tabBoundRect.width;
        }

        leftPos += (index * 2 + 1) * 20;
        setBarPos(leftPos);

        let newWidth: number = currentTab.getBoundingClientRect().width;
        setBarWidth(newWidth);

        setLastTab(currentTab);
    }

    useEffect(() => {
        // if (isHover)
        //     barArray.current.
    }, [isHover]);

    return (
        <Wrapper>
            <HoverDiv onMouseOver={ handleMouseOver } onMouseOut={ handleMouseOut }>
                <Link href="/page/1">
                    <Text transparent={ props.transparent } onMouseOver={ e => handleTabOver(e, 0) } ref={ tabRefArray[0] }>
                        {"음악"}
                    </Text>
                </Link>
                <Link href="/notify">
                    <Text transparent={ props.transparent } onMouseOver={ e => handleTabOver(e, 1) } ref={ tabRefArray[1] }>
                        {"공지사항"}
                    </Text>
                </Link>
                <Link href="/introduce">
                    <Text transparent={ props.transparent } onMouseOver={ e => handleTabOver(e, 2) } ref={ tabRefArray[2] }>
                        {"이용안내"}
                    </Text>
                </Link>
                <Link href="/help">
                    <Text transparent={ props.transparent } onMouseOver={ e => handleTabOver(e, 3) } ref={ tabRefArray[3] }>
                        {"FAQ"}
                    </Text>
                </Link>
                <Link href="/inquiry">
                    <Text transparent={ props.transparent } onMouseOver={ e => handleTabOver(e, 4) } ref={ tabRefArray[4] }>
                        {"문의하기"}
                    </Text>
                </Link>
                <UnderBar width={ barWidth } left={ barPos } visible={ isHover } ref={ barArray } />
            </HoverDiv>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    z-index: 3;
`;

const HoverDiv = styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;
    position: relative;
    justify-content: center;
`;

const Text = styled(P)<{ transparent?: boolean }>`
    margin: 0 20px;
    font-family: Pretendard; 
    font-size: 0.2rem;
    font-weight: bold;
    line-height: normal; 
    color: ${ props => props.transparent ? "white" : "#271565" };
    word-break: keep-all;
    cursor: pointer;

    ${({ theme }: { theme: ThemeType }) => theme.labtop!`
		margin: 0 15px;
	`}

    ${({ theme }: { theme: ThemeType }) => theme.xlargeTablet!`
		margin: 0 10px;
        font-size: 0.18rem;
	`}
`;

const UnderBar = styled.div<{ width: number, left: number, visible: boolean }>`
    width: ${ props => props.width }px;
    height: 4px; 
    position: absolute;
    background: #F3B25A; 
    bottom: -8px;
    left: ${ props => props.left }px;
    display: ${ props => props.visible ? "initial" : "none" };
`;