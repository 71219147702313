import axios, { AxiosError, AxiosResponse } from 'axios';
import { addConfig, deleteConfig, LibrariesConfig, listConfig } from './config';
import { CustomError, HTTPError } from 'src/models/error/http';

async function cartlist(userId: { userId: string })
{
	let res = await axios(listConfig(userId))
		.then((res: AxiosResponse<any>) => res.data.results)
		.catch((e: any) => {
			console.log(e);
		});

	return res;
}

async function addItem(data: { libraryId: string, userId: string })
{
	let res = await axios(addConfig(data))
		.then((res: AxiosResponse<any>) => res.data.results)
		.catch((e: any) => {
			console.log(e);
		});

	return res;
}

async function deleteItems(items: { libraryId: string })
{
	let res = await axios(deleteConfig(items))
		.then((res: AxiosResponse<any>) => res)
		.catch((e: AxiosError<CustomError>) => {
			console.log(e);
		});

	return res;
}

async function cartLibraries(userId: { userId: string })
{
	let res = await axios(LibrariesConfig(userId))
		.then((res: AxiosResponse<any>) => res.data.results)
		.catch((e: any) => {
			console.log(e);
		});

	return res;
}

const CartService = {
	cartlist,
	addItem,
	deleteItems,
	cartLibraries
};

export default CartService;
