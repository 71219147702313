import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { AiOutlineMenu } from "react-icons/ai";
import DesktopLogo from 'src/components/icons/DesktopLogo';
import MobileLogo from 'src/components/icons/MobileLogo';
import { ThemeType } from 'src/types/theme';
import MenuBtn from './mobile/button/MenuBtn';

export type HeaderLogoProps = {
	transparent?: boolean;
};

function HeaderLogo(props: HeaderLogoProps)
{
	return (
		<Wrapper>
			<Link href="/page/1">
				<LogoWarpper>
					<DesktopLogo color={ props.transparent ? "white" : "#271565" } />
				</LogoWarpper>
			</Link>
		</Wrapper>
	);
}

export default React.memo(HeaderLogo);

const Wrapper = styled.div`
	height: fit-content;
	display: flex;
	align-items: center;
	flex: 1;
	font-size: 0;
	z-index: 3;
`;

const LogoWarpper = styled.div`
	width: 105px;
	position: relative;
`;