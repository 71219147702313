import React, { useState } from 'react';
// import styled from 'styled-components';
import Image from 'next/image';

// import { useLazyImageObserver } from '@src/hooks/image';
// import { Color } from '.';

export type ResImgProps = {
	ratio?: number;
	src: string;
	alt?: string;
	imgStyle?: React.CSSProperties;
	style?: React.CSSProperties;
	collapse?: 'width' | 'height';
	lazyLoad?: boolean;
};

export default function ResImg(props: ResImgProps)
{
	// if (lazyLoad) {
	// 	const { imgRef, imgSrc } = useLazyImageObserver(src);
	// 	const [isLoading, setIsLoading] = useState(true);
	// 	const handleLoadEvent = () => {
	// 		setIsLoading(false);
	// 	};

	// 	return (
	// 		<Wrapper ratio={ratio} style={style} ref={imgRef}>
	// 			<ImgWrapper {...{ collapse }} isLoad={isLoading}>
	// 				{imgSrc && <Image src={imgSrc} onLoad={handleLoadEvent} layout="fill" alt={alt} />}
	// 			</ImgWrapper>
	// 		</Wrapper>
	// 	);
	// }
	return (
		<Image
			src={props.src}
			fill
			alt=""
		/>
	);
}

// const Wrapper = styled.div<{ ratio: number; isLoad?: boolean }>`
// 	position: relative;
// 	width: 100%;
// 	overflow: hidden;
// 	height: 0;
// 	padding-top: ${({ ratio }) => `${100 * ratio}%`};
// `;

// const ImgWrapper = styled.div<{ isLoad?: boolean }>`
// 	position: absolute;
// 	top: 0;
// 	left: 50%;
// 	transform: translateX(-50%);
// 	width: 100%;
// 	height: 100%;
// 	background-color: ${({ isLoad }) => (isLoad ? Color.GRAY400 : 'transparent')};
// `;
