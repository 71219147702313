import React from 'react';
import styled from 'styled-components';
import cssMixin from 'src/styles';
import { P, Color } from 'src/components/atoms';
import { ThemeType } from '@src/types/theme';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

export default function FooterInfo()
{
	const { t } = useTranslation("common");

	const FooterInfoDataLeft = [
		{ label: "상호명", text: "주식회사 율컴퍼니(법인)" },
		{ label: "대표자", text: "이나영" },
		{ label: "사업자등록번호", text: '714-87-01958' },
		{ label: "통신판매업번호", text: "2022-경기도성남-0141" },
		{ label: "전화번호", text: '070-8813-2929' },
		{ label: "소재지", text: "경기도 성남시 중원구 양현로 411, 시티오피스타워 602호" }
	];
	
	const FooterInfoDataRight = [
		{ label: "고객센터 (채널톡)", text: 'AM 10:30 ~ PM 6:00 | MON - FRI\n(Lunch PM 12:00 ~ PM 1:30) ' },
		{ label: "이메일", text: '240@yulbgm.com' },
		{ label: "인스타그램", text: '@yulbgm' }
	];

	
	return (
		<Wrapper>
			<StyledP color="#D9D9D9">
				{"by YULCOMPANY Inc"}
			</StyledP>
			<Row>
				<Col>
					{ FooterInfoDataLeft.map((data, idx) => (
						<DataWrapper key={ "FooterInfoDataLeft_" + idx }>
							<MobileP2 color="#D9D9D9">{ data.label }</MobileP2>
							<MobileP color="#D9D9D9">{ data.text }</MobileP>
						</DataWrapper>
					))}
				</Col>
				<Col>
					{ FooterInfoDataRight.map((data, idx) => (
						<DataWrapper key={ "FooterInfoDataRight_" + idx }>
							<MobileP2 color="#D9D9D9">{ data.label }</MobileP2>
							<MobileP color="#D9D9D9">{ data.text }</MobileP>
						</DataWrapper>
					))}
					<TermWarp>
						<Link href="/term/serviceterm">
							<MobileP color="#D9D9D9" style={{ marginRight: "20px" }}>{ "이용약관" }</MobileP>
						</Link>
						<Link href="/term/privacypolicy">
							<MobileP color="#D9D9D9">{ "개인정보 보호정책" }</MobileP>
						</Link>
					</TermWarp>
				</Col>
			</Row>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	${ cssMixin.flexCol };
	width: 100%;
	font-family: 'Spoqa Han Sans Neo';
	color: #D9D9D9;
`;

const DataWrapper = styled.div`
	${ cssMixin.flexRow };
	align-items: flex-start;
	white-space: break-spaces;
	display: flex;
	flex-wrap: wrap;
`;

const StyledP = styled(P)`
	font-size: 24px;
	font-weight: bold;
	padding-left: 4.9vw;
	margin-bottom: 0.315rem;

	${({ theme }) => theme.tablet`
		padding-left: 0;
		font-size: 16px;
	`};
`;

const Row = styled.div`
	${ cssMixin.flexRow };
	align-items: start;

	${({ theme }) => theme.tablet`
		display: unset;	
	`};
`;

const Col = styled.div`
	padding-left: 4.9vw;
	flex: 1;

	${({ theme }) => theme.tablet`
		padding-left: 0;
		padding-bottom: 24px;
	`};
`;

const MobileP = styled(P)`
	font-size: 14px;
	font-weight: bold;
	line-height: 32px;

	${({ theme }) => theme.largeTablet`
		line-height: 25px;
	`};
	
	${({ theme }) => theme.tablet`
		line-height: 22px;
		font-size: 11px;
	`};
`;

const MobileP2 = styled(P)`
	font-size: 14px;
 	width: 1.2rem;
	line-height: 32px;
	margin-right: 12px;

	${({ theme }) => theme.largeTablet`
		line-height: 25px;
	`};
	
	${({ theme }) => theme.tablet`
		line-height: 22px;
		font-size: 11px;
	`};
`;

const TermWarp = styled.div`
	margin-top: 30px;
	display: flex;
`;