import cssMixin from 'src/styles';
import { ThemeType } from 'src/types/theme';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import HeaderLogo from '../HeaderLogo';
import Navigation from './navigation/Navigation';
import SearchBox from '../../../../layouts/main/top/search/SearchBox';
import CenterButtons from './centerbuttons/CenterButtons';

export type DesktopHeaderProps = {
	transparent?: boolean
};

export default function DesktopHeader(props: DesktopHeaderProps)
{
	useEffect(() => {
		console.log("DesktopHeader");
	}, []);

	return (
		<Wrapper transparent={ props.transparent }>
			<HeaderLogo transparent={ props.transparent } />
			<CenterButtons transparent={ props.transparent } />
			<Navigation transparent={ props.transparent } />
		</Wrapper>
	);
}

const Wrapper = styled.div<{ transparent?: boolean }>`
	${ cssMixin.container }
	${ cssMixin.flexRow }
	justify-content: space-between;
	align-content: stretch;
	height: 1rem;
    padding: 0 30px;

	${({ theme }: { theme: ThemeType }) => theme.largeTablet!`
		display: none;
	`}
`;
