import React from 'react';
import Image from 'next/image';
import Logo from "public/images/logo/logo_only_text.svg"

export type DesktopLogoProps = {
	color: string;
};

export default function DesktopLogo(props: DesktopLogoProps)
{
	return (
		<svg
			version="1.1" 
			xmlns="http://www.w3.org/2000/svg"
			width="100%" viewBox="0 0 4730 865"
			enableBackground="new 0 0 4730 865"
		>
		<path fill={ props.color } opacity="1.000000" stroke="none" 
			d="
		M4730.011719,849.166626 
			C4715.849121,849.111084 4701.687012,849.014771 4687.524414,849.007690 
			C4645.026367,848.986389 4602.528809,849.000000 4560.030762,849.000000 
			C4558.222656,849.000000 4556.414062,849.000000 4554.000000,849.000000 
			C4554.000000,846.914124 4554.000000,845.146179 4554.000000,843.378174 
			C4554.000000,708.051208 4553.906738,572.724060 4554.169434,437.397583 
			C4554.191406,426.002899 4556.629395,414.615967 4557.889648,403.219391 
			C4558.647461,396.367676 4559.242188,389.497894 4560.013184,382.647858 
			C4561.275879,371.420471 4562.693848,360.210236 4563.915039,348.978516 
			C4565.033691,338.698883 4565.894531,328.391113 4567.022949,318.112701 
			C4568.184570,307.536072 4569.613770,296.988708 4570.774902,286.411957 
			C4571.685059,278.124603 4572.365234,269.811890 4573.131836,261.678619 
			C4568.444336,259.777039 4566.652344,261.675629 4565.001465,265.501953 
			C4550.952148,298.054321 4536.133301,330.286346 4522.683105,363.081360 
			C4513.668457,385.062805 4501.741699,405.251373 4489.261230,425.265045 
			C4473.070801,451.228516 4457.096680,477.327271 4440.923828,503.301819 
			C4425.174805,528.595642 4409.237793,553.772705 4393.484375,579.063721 
			C4378.594238,602.968567 4363.829590,626.951477 4349.020020,650.906250 
			C4339.346191,666.553833 4329.762207,682.258728 4319.913086,697.795227 
			C4318.916016,699.368408 4316.376465,700.834106 4314.530273,700.861755 
			C4298.534180,701.101013 4282.532227,701.099731 4266.536133,700.864746 
			C4264.672363,700.837402 4262.133789,699.426514 4261.090820,697.866455 
			C4242.441895,669.967651 4223.940430,641.970581 4205.482422,613.944702 
			C4190.881348,591.774292 4176.401855,569.523193 4161.833008,547.331299 
			C4140.113281,514.246460 4118.353516,481.187744 4096.627441,448.107056 
			C4084.466553,429.590973 4071.994629,411.265411 4060.358887,392.424164 
			C4055.449707,384.474854 4052.209717,375.472504 4048.431641,366.852173 
			C4042.297607,352.856171 4036.400391,338.756592 4030.302734,324.744446 
			C4021.800293,305.207581 4013.022461,285.787567 4004.799561,266.134827 
			C4003.080566,262.026337 4001.039795,261.375732 3997.056396,262.415588 
			C3997.835693,267.901947 3998.577148,273.341827 3999.386963,278.771545 
			C4000.903320,288.944000 4002.464600,299.109741 4003.997803,309.279724 
			C4005.555176,319.611115 4007.120605,329.941284 4008.644287,340.277649 
			C4010.121338,350.298859 4011.524414,360.330933 4013.007080,370.351227 
			C4014.536133,380.685852 4016.265625,390.992889 4017.645508,401.346893 
			C4019.245850,413.355133 4021.786133,425.398682 4021.812744,437.431488 
			C4022.109619,572.424500 4022.000000,707.418457 4022.000000,842.412109 
			C4022.000000,844.389954 4022.000000,846.367798 4022.000000,848.671753 
			C3966.327881,848.671753 3910.941162,848.671753 3855.276855,848.671753 
			C3855.276855,571.898254 3855.276855,295.185730 3855.276855,18.000031 
			C3857.083008,18.000031 3858.703369,18.000032 3860.323975,18.000032 
			C3910.155029,18.000025 3959.985840,18.034866 4009.816650,17.924831 
			C4013.291748,17.917158 4015.479980,18.639174 4017.432617,21.820408 
			C4031.824951,45.265865 4046.416504,68.589554 4060.996826,91.919174 
			C4071.610352,108.901237 4082.328125,125.818077 4092.953857,142.792557 
			C4107.982422,166.800720 4122.994141,190.819534 4137.966797,214.862411 
			C4159.046875,248.711624 4180.020996,282.626678 4201.161133,316.438049 
			C4217.130371,341.979034 4233.858887,367.065094 4249.162598,392.995117 
			C4259.418457,410.372040 4267.865723,428.824127 4276.944824,446.884460 
			C4283.688965,460.299255 4290.291504,473.790283 4296.640625,487.394867 
			C4298.387695,491.138214 4300.343750,493.025116 4304.426758,491.720947 
			C4311.575684,474.628143 4318.751465,457.577484 4325.838379,440.489807 
			C4331.377441,427.133270 4336.260254,413.470978 4342.463867,400.434540 
			C4347.059570,390.776520 4353.294434,381.896545 4358.825684,372.685944 
			C4370.392090,353.426697 4381.958984,334.167786 4393.557129,314.927704 
			C4407.759277,291.367126 4422.022949,267.843445 4436.206055,244.271271 
			C4448.711914,223.486313 4461.109863,202.636017 4473.617676,181.852249 
			C4487.802734,158.281906 4502.090820,134.773544 4516.270996,111.200195 
			C4527.682129,92.229950 4539.002930,73.205414 4550.356934,54.200623 
			C4556.786621,43.438103 4563.322754,32.736065 4569.549316,21.857019 
			C4571.395996,18.629948 4573.642578,17.912300 4577.145020,17.920284 
			C4628.117676,18.036467 4679.089844,18.000004 4730.531250,18.000000 
			C4731.000000,294.687561 4731.000000,571.375122 4730.748047,848.544067 
			C4730.334473,849.072449 4730.173340,849.119507 4730.011719,849.166626 
		z"/>
		<path fill={ props.color } opacity="1.000000" stroke="none" 
			d="
		M3513.562500,0.999997 
			C3516.917236,1.666597 3519.802734,2.647034 3522.757080,2.946471 
			C3536.968994,4.386897 3551.210205,5.540558 3565.421631,6.985546 
			C3601.833740,10.687898 3637.245117,19.548725 3672.706299,28.093456 
			C3679.591553,29.752520 3686.295410,32.165668 3693.187256,34.262722 
			C3680.873779,83.508789 3668.703613,132.181030 3656.412354,181.339096 
			C3650.822510,179.692108 3645.466309,177.814224 3639.969482,176.547867 
			C3619.860840,171.915390 3599.824707,166.818390 3579.529053,163.198410 
			C3564.075439,160.442078 3548.304932,159.083786 3532.612793,158.139023 
			C3511.950195,156.895020 3491.200928,155.715393 3470.535889,156.267914 
			C3449.960205,156.818024 3429.470947,159.314819 3409.198242,163.746704 
			C3386.612549,168.684296 3364.962646,175.744904 3344.618652,186.755280 
			C3331.193848,194.021088 3318.985352,202.890610 3307.406494,212.878372 
			C3292.047363,226.126740 3279.584229,241.608383 3269.052979,258.777710 
			C3257.068115,278.317139 3247.979004,299.192810 3242.162598,321.318512 
			C3238.405762,335.610260 3235.311035,350.157349 3233.186035,364.774139 
			C3230.991211,379.870514 3230.004150,395.170349 3229.094727,410.417328 
			C3228.294678,423.821442 3227.543457,437.314056 3228.198486,450.697754 
			C3229.023438,467.558624 3230.515137,484.473145 3233.165283,501.134491 
			C3235.892334,518.280151 3239.016113,535.592712 3244.440430,552.014954 
			C3249.915283,568.590759 3257.582275,584.617981 3265.870605,600.035217 
			C3272.774658,612.877380 3281.281494,625.029480 3290.316162,636.513672 
			C3303.206543,652.899536 3319.475098,665.793274 3337.028564,676.996521 
			C3357.996094,690.378723 3380.960938,698.867004 3405.181396,703.898926 
			C3416.187500,706.185547 3427.379639,708.047424 3438.576416,708.830017 
			C3453.457764,709.870300 3468.513428,710.847412 3483.338867,709.753235 
			C3513.845703,707.501587 3543.829590,702.184204 3571.450195,687.866882 
			C3572.030518,687.566101 3572.595947,687.233154 3573.190918,686.965942 
			C3580.576416,683.650269 3583.427002,678.740051 3583.290527,669.917297 
			C3582.641357,627.929749 3583.000000,585.926575 3583.000000,543.928955 
			C3583.000000,542.127930 3583.000000,540.326965 3583.000000,538.210266 
			C3517.205322,531.871277 3451.766113,525.566589 3386.197266,519.249329 
			C3386.197266,483.302979 3386.197266,448.012482 3386.197266,412.360809 
			C3499.271240,412.360809 3612.322021,412.360809 3726.000000,412.360809 
			C3726.000000,414.073334 3726.000000,415.839874 3726.000000,417.606445 
			C3726.000000,538.599548 3725.986328,659.592712 3726.074707,780.585754 
			C3726.077148,783.814636 3725.296387,785.962463 3722.664551,787.941528 
			C3703.673584,802.223267 3683.114990,813.681946 3661.417969,823.333679 
			C3632.491943,836.201172 3602.423340,845.493042 3571.665283,852.539429 
			C3557.545410,855.774170 3543.128174,857.785461 3528.783203,859.935181 
			C3519.059082,861.392456 3509.226318,862.124878 3498.739258,863.581909 
			C3491.357666,864.654785 3484.678955,865.327393 3478.000000,866.000000 
			C3462.645752,866.000000 3447.291748,866.000000 3431.267090,865.614258 
			C3425.397705,865.485718 3420.198730,865.742859 3415.000000,866.000000 
			C3414.250000,866.000000 3413.500000,866.000000 3412.375000,866.000000 
			C3410.824707,865.333984 3409.698975,864.204895 3408.466553,864.072876 
			C3394.927490,862.622192 3381.246826,862.067322 3367.851318,859.807556 
			C3349.905762,856.780396 3332.014160,853.100769 3314.393311,848.560120 
			C3285.805664,841.193542 3258.271484,830.718506 3232.297607,816.594849 
			C3213.654541,806.457520 3195.754883,795.047791 3179.656738,781.099121 
			C3169.560791,772.351501 3159.181641,763.818848 3149.950928,754.208618 
			C3131.887451,735.402588 3116.296143,714.617920 3102.968506,692.124634 
			C3085.879150,663.282043 3072.140381,632.944153 3063.103271,600.702209 
			C3057.499756,580.710327 3052.822754,560.373291 3049.235107,539.927124 
			C3046.070068,521.889282 3044.177246,503.529236 3043.187012,485.232239 
			C3041.762207,458.913696 3040.723145,432.502411 3041.307861,406.170410 
			C3041.750732,386.221130 3044.826172,366.316223 3047.016846,346.423126 
			C3050.201172,317.507080 3057.333496,289.483917 3066.968262,262.087921 
			C3074.986328,239.288132 3084.932617,217.331253 3097.730225,196.814484 
			C3107.458008,181.219070 3117.326416,165.645813 3128.199707,150.846878 
			C3141.443359,132.822327 3157.418701,117.181595 3174.114258,102.273293 
			C3189.167969,88.830833 3205.355225,76.960373 3222.360107,66.187210 
			C3243.891846,52.546089 3266.491455,40.781265 3290.536377,32.415977 
			C3311.509766,25.119444 3332.954102,19.035650 3354.463135,13.470829 
			C3367.985352,9.972401 3382.005615,8.387647 3395.814941,6.017091 
			C3399.042480,5.463068 3402.320312,5.203945 3406.258057,4.413482 
			C3412.704346,3.673872 3418.482666,3.483013 3424.224121,2.917366 
			C3426.853516,2.658350 3429.410400,1.662006 3432.000000,1.000024 
			C3455.354248,1.000000 3478.708252,1.000000 3502.659180,1.336845 
			C3504.503662,1.449133 3505.751953,1.224576 3507.000000,1.000010 
			C3509.041748,1.000000 3511.083252,1.000000 3513.562500,0.999997 
		z"/>
		<path fill={ props.color } opacity="1.000000" stroke="none" 
			d="
		M1.988001,17.833334 
			C3.984322,17.888889 5.980636,17.992744 7.976964,17.993000 
			C73.975166,18.001465 139.973373,18.006405 205.971558,17.976976 
			C208.651642,17.975782 210.572296,18.048403 212.175339,21.126122 
			C219.628448,35.435650 227.505005,49.525375 235.278259,63.667248 
			C248.469025,87.665146 261.754669,111.611084 274.884705,135.642090 
			C282.152130,148.943161 289.093964,162.421936 296.342529,175.733505 
			C309.827484,200.497787 323.460785,225.181229 336.969696,249.932526 
			C343.841217,262.522675 350.522614,275.216522 357.391205,287.808289 
			C362.464935,297.109589 368.285217,306.055084 372.676788,315.660248 
			C376.009277,322.948883 378.092194,330.917694 379.891235,338.776123 
			C383.701721,355.421021 386.947845,372.194824 390.457306,388.909027 
			C392.056976,396.527344 393.732330,404.129761 395.353851,411.648926 
			C398.751984,412.738068 400.338867,411.538971 401.029266,408.258057 
			C403.941956,394.416901 406.955231,380.596466 410.017792,366.787506 
			C413.290161,352.032684 416.491852,337.258148 420.099823,322.584503 
			C421.045074,318.740143 423.241333,315.164429 425.105286,311.591644 
			C433.334351,295.818085 441.651215,280.090363 449.937378,264.346588 
			C462.623230,240.243286 475.284424,216.126953 488.005249,192.042099 
			C496.613464,175.743805 505.339691,159.507874 513.953674,143.212601 
			C529.656433,113.507362 545.318176,83.780434 560.990417,54.059059 
			C566.695312,43.240036 572.416931,32.429508 578.048096,21.572140 
			C579.281921,19.193275 580.593628,17.905586 583.671814,17.911592 
			C644.836548,18.030935 706.001648,18.000593 767.166626,18.007780 
			C767.979187,18.007877 768.791748,18.100681 770.224060,18.187996 
			C765.494019,26.709257 761.005249,34.921261 756.398682,43.066658 
			C748.623657,56.814518 740.785583,70.526802 732.953003,84.242035 
			C722.128723,103.195786 711.260010,122.124184 700.457458,141.090302 
			C691.669006,156.520126 682.961487,171.995972 674.197144,187.439529 
			C663.265625,206.701874 652.289001,225.938644 641.376831,245.211929 
			C633.590332,258.964600 625.921082,272.783752 618.122070,286.529266 
			C607.595581,305.081909 596.954224,323.569458 586.438965,342.128448 
			C578.568726,356.018982 570.881775,370.013428 563.001709,383.898285 
			C552.552673,402.309631 541.952942,420.635437 531.497681,439.043304 
			C523.612000,452.927032 515.900757,466.909790 508.023285,480.798218 
			C497.579132,499.211884 486.963776,517.529297 476.648102,536.014160 
			C475.127899,538.738220 474.111420,542.155518 474.107330,545.253723 
			C473.976868,644.417603 474.000000,743.581543 474.000000,842.745544 
			C474.000000,844.572388 474.000000,846.399292 474.000000,848.612915 
			C415.112305,848.612915 356.391235,848.612915 297.000000,848.612915 
			C297.000000,846.809082 297.000000,844.881287 297.000000,842.953430 
			C297.000000,746.622742 296.873047,650.291565 297.217926,553.962097 
			C297.248688,545.365845 294.676208,538.344604 290.704559,531.334717 
			C281.070282,514.330261 271.419983,497.334839 261.736938,480.358124 
			C251.004150,461.540955 240.184464,442.773224 229.484787,423.937317 
			C221.598526,410.054260 213.887772,396.071594 206.020554,382.177612 
			C195.187775,363.046295 184.244492,343.977539 173.418457,324.842438 
			C165.557098,310.947449 157.849945,296.965240 150.000702,283.063354 
			C138.870193,263.350006 127.653946,243.685074 116.509407,223.979614 
			C108.971748,210.651733 101.534592,197.266922 93.979919,183.948730 
			C84.497398,167.231934 74.907227,150.576187 65.426430,133.858398 
			C57.630241,120.111137 49.948696,106.298904 42.175407,92.538589 
			C30.879965,72.543358 19.551992,52.566486 8.227399,32.587730 
			C6.068183,28.778460 3.860323,24.996761 1.337453,21.101170 
			C1.000000,20.250000 1.000000,19.500000 1.251877,18.362244 
			C1.665169,17.927435 1.826585,17.880384 1.988001,17.833334 
		z"/>
		
		
		<path fill={ props.color } opacity="1.000000" stroke="none" 
			d="
		M2310.000000,344.000000 
			C2310.000000,240.172699 2310.000000,136.845398 2310.000000,33.518108 
			C2310.000000,23.187992 2309.995850,23.151640 2319.878662,22.018005 
			C2337.620605,19.982838 2355.355469,17.868895 2373.118164,16.028597 
			C2387.822998,14.505092 2402.550293,13.146028 2417.292480,12.046869 
			C2433.573242,10.832980 2449.873047,9.812535 2466.180664,9.047171 
			C2484.868652,8.170091 2503.569092,7.413828 2522.272949,7.057969 
			C2551.366211,6.504429 2580.475342,5.779155 2609.562012,6.198834 
			C2628.304932,6.469267 2647.050049,8.335797 2665.738281,10.083337 
			C2682.521973,11.652740 2699.340332,13.383681 2715.943604,16.215355 
			C2740.152588,20.344189 2763.943604,26.441553 2786.866211,35.450062 
			C2812.024414,45.337097 2835.963135,57.346172 2856.719482,75.111664 
			C2874.895996,90.669189 2890.519287,108.246384 2901.231445,129.752197 
			C2909.044189,145.437119 2914.378174,162.096878 2915.839355,179.568283 
			C2917.258057,196.531158 2918.582275,213.671997 2917.691406,230.616013 
			C2916.508301,253.117676 2911.120605,274.950989 2902.140137,295.861816 
			C2895.186523,312.053528 2886.159912,326.895172 2874.982178,340.355743 
			C2862.093994,355.876556 2846.395020,368.182678 2829.062744,378.510498 
			C2806.817383,391.766296 2782.469482,399.043549 2757.320801,404.294769 
			C2756.447021,408.136169 2756.821045,410.400116 2761.598877,411.120056 
			C2775.284912,413.182190 2789.198486,414.723511 2802.433594,418.522278 
			C2817.041260,422.715118 2831.421143,428.295898 2845.136230,434.867035 
			C2866.999756,445.342010 2885.264160,460.737885 2900.948975,479.326477 
			C2920.039795,501.951782 2932.895020,527.719666 2939.449951,556.371216 
			C2942.571289,570.014709 2943.416260,584.201904 2944.922119,598.182556 
			C2945.699219,605.400391 2946.166748,612.722778 2945.914795,619.968018 
			C2944.726807,654.144470 2938.867676,687.328796 2921.130127,717.157959 
			C2913.742676,729.581299 2904.867432,741.224243 2895.789551,752.515259 
			C2880.089355,772.042969 2860.733154,787.557495 2839.602051,800.896851 
			C2821.091064,812.582336 2801.659424,822.579224 2781.026367,829.778931 
			C2762.963379,836.081909 2744.541016,841.491638 2726.018311,846.296448 
			C2712.810303,849.722473 2699.239746,851.963196 2685.709717,853.877319 
			C2670.136230,856.080627 2654.442627,857.479370 2638.776123,858.962402 
			C2628.936768,859.893738 2619.055664,860.825806 2609.186035,860.908752 
			C2579.189941,861.160706 2549.189941,861.101440 2519.192383,860.954590 
			C2505.425781,860.887207 2491.656738,860.465881 2477.897705,859.960327 
			C2464.526367,859.469116 2451.159180,858.786682 2437.803955,857.964661 
			C2423.998291,857.114868 2410.173828,856.335266 2396.421143,854.913330 
			C2377.043213,852.909851 2357.710449,850.443542 2338.384766,847.970764 
			C2329.977051,846.895020 2321.605957,845.455322 2313.291992,843.808533 
			C2311.992676,843.551270 2310.792236,841.437195 2310.100342,839.927734 
			C2309.595215,838.825806 2310.000000,837.306580 2310.000000,835.973328 
			C2310.000000,672.148865 2310.000000,508.324432 2310.000000,344.000000 
		M2480.000000,605.500000 
			C2480.000000,640.476929 2480.000000,675.453796 2480.000000,711.295349 
			C2490.341797,712.544128 2500.481201,714.176758 2510.685059,714.903198 
			C2525.354492,715.947388 2540.068359,716.820190 2554.767334,716.884827 
			C2577.360107,716.984253 2599.964355,716.601807 2622.548340,715.914673 
			C2639.078369,715.411743 2655.302246,712.514465 2671.279541,708.083984 
			C2695.630127,701.331665 2717.488770,690.579041 2735.488037,672.215759 
			C2752.496826,654.863281 2760.224609,634.036011 2760.854492,610.328735 
			C2761.089111,601.495178 2760.075684,592.564453 2758.920166,583.771606 
			C2756.602783,566.142639 2749.194092,550.745667 2737.135986,537.653137 
			C2720.320801,519.395142 2698.538330,510.194092 2675.173096,504.029541 
			C2654.289307,498.519745 2632.789795,497.343170 2611.399414,496.171661 
			C2590.846924,495.046021 2570.218018,495.198761 2549.621094,495.037201 
			C2528.292725,494.869904 2506.962402,495.000000 2485.632812,495.000000 
			C2483.863770,495.000000 2482.094971,495.000000 2480.000000,495.000000 
			C2480.000000,531.904602 2480.000000,568.202332 2480.000000,605.500000 
		M2480.000000,354.458496 
			C2480.000000,357.558167 2480.000000,360.657837 2480.000000,363.469971 
			C2480.819336,363.785156 2480.967285,363.892700 2481.114502,363.891602 
			C2520.200684,363.598358 2559.288574,363.424835 2598.371582,362.883362 
			C2605.688232,362.782043 2613.164062,361.933350 2620.256592,360.177521 
			C2642.369629,354.703217 2662.930908,345.189270 2682.459473,333.683075 
			C2696.989502,325.121765 2710.962891,315.675110 2721.601807,302.029297 
			C2731.642090,289.151367 2737.297607,274.507751 2738.743164,258.598389 
			C2739.725342,247.793411 2739.153076,236.666367 2737.776855,225.874573 
			C2736.133545,212.988647 2730.996826,201.153809 2722.523193,191.006180 
			C2706.591553,171.928040 2685.419678,161.237961 2661.827881,156.394806 
			C2642.177979,152.360886 2621.960449,150.386292 2601.896240,149.285187 
			C2583.542480,148.277939 2565.048340,149.415466 2546.632324,150.060455 
			C2526.332275,150.771408 2506.054932,152.113159 2485.757080,152.910370 
			C2481.299316,153.085449 2479.894043,154.471710 2479.904053,159.028381 
			C2480.044922,223.842422 2480.000000,288.656860 2480.000000,354.458496 
		z"/>
		<path fill={ props.color } opacity="1.000000" stroke="none" 
			d="
		M1102.378784,696.728149 
			C1115.539062,699.934143 1128.208984,704.243835 1141.191406,705.633179 
			C1159.252930,707.566040 1177.585693,708.349365 1195.733276,707.697388 
			C1213.446045,707.061035 1231.038208,704.037720 1248.006836,698.119812 
			C1265.894165,691.881470 1281.989136,682.733826 1295.171631,669.166199 
			C1309.438721,654.482239 1318.621704,636.626160 1323.869141,616.965942 
			C1327.218384,604.417969 1329.391479,591.549500 1331.892212,578.785645 
			C1332.615356,575.094482 1332.799072,571.274719 1332.965942,567.502197 
			C1333.390137,557.907288 1333.953979,548.305847 1333.957275,538.706848 
			C1334.016235,367.548828 1334.000000,196.390839 1334.000000,25.232830 
			C1334.000000,23.074678 1334.000000,20.916527 1334.000000,18.379522 
			C1389.568726,18.379522 1444.955566,18.379522 1501.000000,18.379522 
			C1501.000000,20.039240 1501.000000,21.801746 1501.000000,23.564253 
			C1501.000000,204.221786 1501.137451,384.879608 1500.782959,565.536438 
			C1500.748291,583.193726 1497.866211,600.864441 1495.981934,618.494324 
			C1493.531860,641.418335 1487.961182,663.665161 1479.965820,685.249390 
			C1470.188721,711.644287 1457.770630,736.696289 1439.807129,758.605652 
			C1432.500122,767.517883 1425.087280,776.451782 1416.825317,784.448914 
			C1402.212524,798.593384 1385.804077,810.484375 1368.047485,820.521973 
			C1350.831055,830.254211 1332.839355,838.108765 1313.993896,844.047852 
			C1293.946655,850.365601 1273.615356,855.492493 1252.709473,857.972351 
			C1239.681885,859.517700 1226.634888,861.302002 1213.548950,861.859131 
			C1192.973755,862.735168 1172.335205,863.412659 1151.767578,862.767761 
			C1134.576294,862.228821 1117.445190,859.705505 1100.292725,858.006348 
			C1069.998901,855.005432 1040.557861,847.957947 1011.960754,837.812927 
			C982.322266,827.298462 954.744385,812.790833 930.151367,792.804504 
			C912.238892,778.247314 896.708679,761.665833 883.869141,742.524658 
			C867.179260,717.643433 855.581116,690.459106 849.213135,661.301880 
			C845.426147,643.962646 842.803467,626.257324 841.230957,608.576599 
			C839.531738,589.471313 839.104004,570.203491 839.087646,551.005737 
			C838.938538,375.681396 839.000000,200.356857 839.000000,25.032396 
			C839.000000,22.890965 839.000000,20.749533 839.000000,18.308655 
			C898.044800,18.308655 956.764465,18.308655 1016.000000,18.308655 
			C1016.000000,20.533680 1016.000000,22.648960 1016.000000,24.764240 
			C1016.000000,202.088608 1015.711243,379.413910 1016.275574,556.736511 
			C1016.373169,587.369629 1022.068176,617.538940 1037.037354,645.065918 
			C1046.286011,662.073364 1059.593872,675.267944 1076.427734,684.641174 
			C1084.625854,689.205872 1093.473999,692.603271 1102.378784,696.728149 
		z"/>
		<path fill={ props.color } opacity="1.000000" stroke="none" 
			d="
		M2062.000000,694.000000 
			C2115.463379,694.000000 2168.426514,694.000000 2221.694580,694.000000 
			C2221.694580,745.714478 2221.694580,797.097473 2221.694580,848.740479 
			C2030.874268,848.740479 1840.172607,848.740479 1649.235474,848.740479 
			C1649.235474,571.953369 1649.235474,295.378632 1649.235474,18.401905 
			C1707.853149,18.401905 1766.569214,18.401905 1825.776123,18.401905 
			C1825.776123,243.259766 1825.776123,468.291595 1825.776123,694.000000 
			C1904.676147,694.000000 1983.088013,694.000000 2062.000000,694.000000 
		z"/>
		</svg>
	)
}