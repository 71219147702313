import { ThemeType } from '@src/types/theme';
import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import DesktopHeader from './desktop/DesktopHeader';
import { useIsDesktop } from '@src/hooks/util';

const MobileHeader = dynamic(() => import('./mobile/MobileHeader'));

export type HeaderProps = {
	disableScroll?: boolean;
	isBack?: boolean
	transparent?: boolean;
};

function Header(props: HeaderProps)
{
	const isDesktop = useIsDesktop();

	useEffect(() => {
		console.log("header");
	}, []);

	return (
		<Wrapper transparent={ props.transparent } className="notranslate">
			{ isDesktop ?
				<DesktopHeader transparent={ props.transparent } />
				:
				<MobileHeader { ...props } />
			}
		</Wrapper>
	);
}

export default React.memo(Header);

const Wrapper = styled.header<{ transparent?: boolean }>`
	width: 100%; 
	background: ${ props => !props.transparent && "white" };

	${({ theme }: { theme: ThemeType }) => theme.breakpoint.labtop!`
		margin-top: 0;
	`}
`;
